<template>
  <div class="form-wrapper">
    <div class="action-button-wrapper">
      <button class="btn btn-primary saveButton" @click="save">{{ $t('Update Customer')}}</button>
    </div>
    <div class="customer-infomration">
      <el-card class="box-card customer-card">
        <div slot="header" class="clearfix">
          <span>{{ $t('Customer information') }}</span>
        </div>
        <el-form class="customer-form" ref="customer-form" :model="form" label-width="150px" label-position="left">
          <div class="create-customer-form">
            <div class="row" style="width: 80%">
              <el-form-item :label="$t('Name')" style="width: 100%">
                <el-input v-model="form.customerName"></el-input>
              </el-form-item>
            </div>
            <div class="row">
              <el-form-item class="email" :label="$t('Email')" style="max-width: 80%">
                <el-input v-model="form.customerEmail"></el-input>
              </el-form-item>
              <el-form-item :label="$t('Phone Number')" style="max-width: 80%">
                <el-input v-model="form.customerPhone"></el-input>
              </el-form-item>
            </div>
            <div class="row" style="width: 80%">
              <el-form-item :label="$t('Customer Address')" style="width: 100%">
                <el-input v-model="form.customerAddress"></el-input>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </el-card>
      <el-card class="box-card customer-orders-card">
        <div slot="header" class="clearfix">
          <span>{{ $t('Related Orders') }}</span>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">{{ $t('Order No.') }}</th>
              <th scope="col">{{ $t('Date') }}</th>
              <th scope="col">{{ $t('Order Status') }}</th>
              <th scope="col">{{ $t('Total') }}</th>
              <th scope="col">{{ $t('View') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in relatedOrders">
              <td>{{ order.reference_id }}</td>
              <td>{{ formatDateTime(order.create_date, 'YYYY-MM-DD HH:mm:ss') }}</td>
              <td>{{ $t(order.order_status) }}</td>
              <td>HK$ {{ order.order_amount }}</td>
              <td>
                <div class="action-links">
                  <router-link class="action-link" :to="{name:'Order', params: {id: order.id}}">
                    {{ $t('View') }}
                  </router-link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </el-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Common from '@/lib/common';

export default {
  name: 'CustomerForm',
  data(){
    //Default form value
    let form = {
      customerName: '',
      customerEmail: '',
      customerPhone: '',
      customerAddress: '',
    };

    return {
      form,
      relatedOrders: [],
    };
  },
  methods:{
    formatDateTime(timestamp, format){
      return Common.formatDateTime(timestamp, format);
    },
    save(){
      this.$emit('action', this.form);
    },
  },
  watch: {
    'currentCustomer': function(){
      this.form = {
        customerName: this.currentCustomer.customer_name,
        customerEmail: this.currentCustomer.customer_email,
        customerPhone: this.currentCustomer.customer_phone,
        customerAddress: this.currentCustomer.customer_address,
      };
      this.relatedOrders = this.currentCustomer.related_orders;
    },
  },
  computed: mapState({
    currentCustomer: state => state.customer.currentCustomer,
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.customer{
  .action-button-wrapper{
    text-align: right;
    margin-bottom: 50px;
  }
  .form-wrapper{
    padding-left: 20px;
  }
  .customer-form{
    padding-left: 20px;
  }
  .el-card__header{
    background-color: black;
    color: white;
  }
  .el-form-item.email{
    margin-right: 30px;
  }
  .customer-orders-card{
    margin-top: 50px;
  }
}
</style>
